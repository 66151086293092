/**
 * Creates the mapview.
 */

// import 'mdbootstrap/img/svg/arrow_left.svg';
// import 'mdbootstrap/img/svg/arrow_right.svg';
// import 'mdbootstrap/css/mdb.min.css';
import './css/default.less';

import './libs/defaultModules';

const Box = require('./libs/Box');
const BoxArray = require('./libs/BoxArray');
import Map from './libs/Map';
const Maps = new Map(false, true, false);
let boxarr;
let previousLatLon = boxlocatorHtmlCurrCity.city.latitude + '/' + boxlocatorHtmlCurrCity.city.longitude;


const findLocation = function findLocation(language, location) {
    if (location.city.latitude !== undefined && location.city.longitude !== undefined) {
        return new Promise((resolve, reject) => {
            return resolve([{lat: location.city.latitude, lng: location.city.longitude}]);
        });
    }
};

const loadBoxesForLocation = function loadBoxesForLocation(geoNamesLocation, category) {
    const currBox = new Box(geoNamesLocation.lat, geoNamesLocation.lng, undefined, undefined);
    const boxTypes = currBox.getTypesByCategory(category);

    // Initialize maps and load boxes
    Maps.createMaps(boxTypes, null, true);
    if (boxarr == null) boxarr = new BoxArray(currBox, '#box-list_', boxTypes);
    boxarr.resetBoxArray(currBox);
    boxarr.load(geoNamesLocation.lat, geoNamesLocation.lng);
};

/**
 ' Updates all views of the page.
 * @param geoNamesLocation GeoName Location
 * @param category Name of the category to load
 */
const updateViewsWithLocation = function updateViewsWithLocation(geoNamesLocation, category) {
    console.log('Updating all views.');
    loadBoxesForLocation(geoNamesLocation, category);
    Maps.updateMaps([geoNamesLocation.lat, geoNamesLocation.lng], 13);
    // Maps.startLocationControl(true);

    // Change links and text in HTML
    const newLatLon = geoNamesLocation.lat + '/' + geoNamesLocation.lng;

    if (typeof geoNamesLocation.lat == 'number' && typeof geoNamesLocation.lng == 'number') {
        document.getElementById('location_text_lat').innerHTML = geoNamesLocation.lat.toFixed(3);
        document.getElementById('location_text_lon').innerHTML = geoNamesLocation.lng.toFixed(3);
    } else {
        document.getElementById('location_text_lat').innerHTML = geoNamesLocation.lat;// .toFixed(3);
        document.getElementById('location_text_lon').innerHTML = geoNamesLocation.lng;// .toFixed(3);
    }

    const anchors = document.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
        anchors[i].href = anchors[i].href.replace(previousLatLon, newLatLon);
    }

    previousLatLon = newLatLon;
};

const enableJqueryEvents = function enableTooltips() {
    $('#button_refresh_location').click(() => {
        getLocation();
    });
};

const getBrowserPosition = function getBrowserPosition(options) {
    return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};

const getLocation = function getLocation() {
    return getBrowserPosition({enableHighAccuracy: true})
        .then((position) => {
            console.log(position);
            if (position.coords) {
                console.info('Updating position with device position: '+JSON.stringify(position.coords));
                updateViewsWithLocation({lat: position.coords.latitude, lng: position.coords.longitude}, boxlocatorHtmlCurrCity.category);

                // Change test to current location
                let lang = 'en';
                let yourLocation = 'Your Location';
                if (boxlocatorHtmlCurrCity != null && boxlocatorHtmlCurrCity.language != null) {
                    lang = boxlocatorHtmlCurrCity.language;
                }

                console.log(JSON.stringify(boxlocatorHtmlCurrCity));
                console.log(lang);

                if (lang === 'it') {
                    yourLocation = 'La Tua Posizione';
                } else if (lang === 'ja') {
                    yourLocation = '現在地';
                } else if (lang === 'de') {
                    yourLocation = 'Dein Standort';
                }

                document.getElementById('location-title').innerHTML = yourLocation;

                return position;
            } else {
                alert('Geolocation is not supported by this browser.');
            }
        })
        .catch((err) => {
            console.error(err.message);
            let msg;
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    msg = 'User denied the request for Geolocation.';
                    break;
                case error.POSITION_UNAVAILABLE:
                    msg = 'Location information is unavailable.';
                    break;
                case error.TIMEOUT:
                    msg = 'The request to get user location timed out.';
                    break;
                case error.UNKNOWN_ERROR:
                    msg = 'An unknown error occurred.';
                    break;
            }
            console.error(msg);
        });
};


// Execute page logic
findLocation(document.documentElement.lang, boxlocatorHtmlCurrCity).then((geoResults) => {
    const firstResult = geoResults[0];
    console.log(firstResult);
    updateViewsWithLocation(firstResult, boxlocatorHtmlCurrCity.category);
    getLocation();
}).catch((error) => {
    console.log(error);
    getLocation();
});

enableJqueryEvents();


import './libs/defaultActions';
